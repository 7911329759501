var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"CB72rKcxteG4jSL028p4_"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';
import { UAParser } from 'ua-parser-js';

const config = getConfig().publicRuntimeConfig;
const userAgentParser = new UAParser();

const appName = config.app.name;
const appVersion = config.app.version;
const sentryDsn = config.sentry.dsn;
const sentryEnabledEnvironments = config.sentry.enabledEnvironments;
const sentryIgnoredBrowsers = config.sentry.ignoredBrowsers;
const sentryEnv = config.sentry.env;

const enableDsnKey =
  sentryEnabledEnvironments.includes(sentryEnv) &&
  !sentryIgnoredBrowsers.includes(userAgentParser.getBrowser().name);

Sentry.init({
  dsn: enableDsnKey ? sentryDsn : undefined,
  environment: sentryEnv,
  integrations: [
    new CaptureConsoleIntegration(),
    new ExtraErrorDataIntegration({
      depth: 10,
    }),
    new ReportingObserverIntegration(),
  ],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  normalizeDepth: 10,
  release: `${appName}@${appVersion}-${sentryEnv}`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
});
